<template>
  <div v-if="isLoading">
    <div>
      <h1 class="mr-sm-4 header-tablepage mb-3">LINE SETTING</h1>
    </div>
    <b-tabs v-model="tabIndex">
      <b-tab no-body title="Line Setting">
        <div class="p-3 bg-white">
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputText
              textFloat="Member Liff URL"
              placeholder="Member Liff URL"
              type="text"
              name="member_liff_url"
              class="w-100"
              v-model="form.memberLiffUrl"
            />
            <font-awesome-icon
              class="icon-copy"
              icon="copy"
              id="tooltip-memberLiffUrl"
              @click="copyWord('memberLiffUrl')"
              v-b-tooltip.hover
              title="Copy URL"
            />
            <b-tooltip
              target="tooltip-memberLiffUrl"
              ref="tooltip-memberLiffUrl"
              triggers="click"
              delay.hide="500"
            >
              <b>Copy !</b>
            </b-tooltip>
          </div>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputText
              textFloat="Campaign Liff URL"
              placeholder="Campaign Liff URL"
              type="text"
              name="campaign_liff_url"
              class="w-100"
              v-model="form.campaignLiffUrl"
            />

            <font-awesome-icon
              class="icon-copy"
              icon="copy"
              @click="copyWord('campaignLiffUrl')"
              id="tooltip-campaignLiffUrl"
              v-b-tooltip.hover
              title="Copy URL"
            />
            <b-tooltip
              target="tooltip-campaignLiffUrl"
              ref="tooltip-campaignLiffUrl"
              triggers="click"
              delay.hide="500"
            >
              <b>Copy !</b>
            </b-tooltip>
          </div>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputText
              textFloat="Profile Complete URL"
              class="w-100"
              type="text"
              placeholder="Profile Complete URL"
              name="profile-complete-url"
              v-model="form.profile_complete_url"
            />
          </div>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputSelect
              title="QR Code"
              class="w-100"
              name="qr_type"
              :options="qrOpt"
              v-model="form.qr_code"
            />
          </div>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputSelect
              title="Year Format"
              class="w-100"
              name="year_format"
              :options="yearFormat"
              v-model="form.year_format"
            />
          </div>

          <hr />
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <UploadFileV2
              textFloat="Font"
              placeholder="Please Upload Font"
              class="w-100"
              format="font"
              name="Font"
              v-on:onFileChange="
                (file) => onFileChange(file, 'custom_font', 'custom_font_name')
              "
              v-on:delete="() => deleteFile('custom_font_name', 'custom_font')"
              :fileName="form.custom_font_name"
              v-model="form.custom_font"
              id="uploadfile"
              :isValidType="false"
              text=""
            />
          </div>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <UploadFileV2
              textFloat="Font Bold"
              placeholder="Please Upload Font"
              class="w-100"
              format="font"
              name="Font-bold"
              v-on:onFileChange="
                (file) =>
                  onFileChange(
                    file,
                    'custom_font_bold',
                    'custom_font_bold_name'
                  )
              "
              v-on:delete="
                () => deleteFile('custom_font_bold_name', 'custom_font_bold')
              "
              :fileName="form.custom_font_bold_name"
              v-model="form.custom_font_bold"
              :value="form.custom_font_bold"
              id="uploadfile2"
              :isValidType="false"
              text=""
            />
          </div>
          <div
            class="col-md-6 col-xl-4 col-lg-6 col-12 d-flex align-items-center pl-0"
          >
            <InputSelect
              title="Font Size"
              class="w-100"
              name="qr_type"
              :options="fontSizeOpt"
              v-model="form.font_size"
            />
          </div></div
      ></b-tab>
      <b-tab no-body title="Display">
        <div class="p-3 bg-white">
          <b-row>
            <b-col cols="12" lg="4" xl="6">
              <b-form-group label="Layout" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="form.line_layout"
                  :options="layoutOpt"
                  :aria-describedby="ariaDescribedby"
                  name="line_layout"
                ></b-form-radio-group>
              </b-form-group>

              <b-form-group
                label="Progress Display"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  v-model="form.progress_display"
                  :options="progressDisplay"
                  :aria-describedby="ariaDescribedby"
                  name="display_progress"
                ></b-form-radio-group>
              </b-form-group>
              <div class="col-form-label">Display</div>
              <b-form-checkbox
                id="display-transaction"
                v-model="form.customer_display_transaction_detail"
                name="display-transaction"
                value="1"
                unchecked-value="0"
              >
                Display Transaction Detail
              </b-form-checkbox>
              <b-form-checkbox
                id="display-point-channel"
                v-model="form.line_display_point_channel"
                name="display-point-channel"
                value="1"
                unchecked-value="0"
              >
                Display Point And Connect Channel
              </b-form-checkbox>
              <b-form-checkbox
                id="display-connect-channel"
                v-model="form.display_connect_channel"
                name="display-connect-channel"
                value="1"
                unchecked-value="0"
              >
                Display Connect Channel
              </b-form-checkbox>
              <b-form-checkbox
                id="display-member-level"
                v-model="form.line_display_member_level"
                name="display-member-level"
                value="1"
                unchecked-value="0"
              >
                Display Member Level
              </b-form-checkbox>
              <b-form-checkbox
                id="display-outstanding"
                v-model="form.line_display_service_outstanding"
                name="display-outstanding"
                value="1"
                unchecked-value="0"
              >
                Display Service Outstanding
              </b-form-checkbox>
              <b-form-checkbox
                id="display-logout-button"
                v-model="form.line_display_logout_button"
                name="display-logout-button"
                value="1"
                unchecked-value="0"
              >
                Display Logout Button
              </b-form-checkbox>
              <b-form-checkbox
                id="display-code-countdown"
                v-model="form.line_display_code_countdown"
                name="display-code-countdown"
                value="1"
                unchecked-value="0"
              >
                Display Code Countdown
              </b-form-checkbox>

              <b-form-checkbox
                id="display-right-request-link"
                v-model="form.line_display_right_request_link"
                name="display-right-request-link"
                value="1"
                unchecked-value="0"
              >
                Display Right Request Link
              </b-form-checkbox>
              <b-form-checkbox
                id="display-redeem-expire"
                v-model="form.line_display_redeem_expire"
                name="display-redeem-expire"
                value="1"
                unchecked-value="0"
              >
                Display Redeem Expire Date
              </b-form-checkbox>
              <b-form-checkbox
                id="display_upcomming_redeem"
                v-model="form.display_upcomming_redeem"
                name="display_upcomming_redeem"
                value="1"
                unchecked-value="0"
              >
                Display Upcomming Redeem
              </b-form-checkbox>
              <b-form-checkbox
                id="display_reward_progress"
                v-model="form.display_reward_progress"
                name="display_reward_progress"
                value="1"
                unchecked-value="0"
              >
                Display Reward Progress (Reward Page)
              </b-form-checkbox>
              <b-form-checkbox
                id="display_point_expired"
                v-model="form.display_point_expired"
                name="display_point_expired"
                value="1"
                unchecked-value="0"
              >
                Display Point Expired
              </b-form-checkbox>
              <b-form-checkbox
                id="line_display_mission"
                v-model="form.line_display_mission"
                name="line_display_mission"
                value="1"
                unchecked-value="0"
              >
                Display Mission
              </b-form-checkbox>
            </b-col>
            <b-col cols="12" lg="8" xl="6">
              <div class="preview-container">
                <div class="wrap-panel">
                  <div class="layout">
                    <template>
                      <div class="navbar">
                        <div>
                          <img
                            :src="
                              $store.state.theme.themeLogo.themeLogoDomain +
                              $store.state.theme.themeLogo.result.themeLogoLine
                            "
                            class="logo-img"
                          />
                        </div>
                      </div>

                      <ProfileDisplay
                        :layout="form.line_layout"
                        :displayMemberLevel="form.line_display_member_level"
                        :displayLogout="form.line_display_logout_button"
                        :displayPoint="form.line_display_point_channel"
                        :displayConnect="form.display_connect_channel"
                      />
                    </template>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-tab>
      <b-tab title="Custom JS">
        <b-container class="no-gutters bg-white py-3 custom-js">
          <InputTextArea
            type="text"
            placeholder=""
            class="text-area"
            textFloat="Header JS Code"
            rows="12"
            v-model="form.custom_js"
          />
        </b-container>
      </b-tab>
      <b-tab title="Label">
        <div class="bg-white p-3">
          <GeneralLabel
            :form="form"
            ref="generalLabel"
            :fetchVariable="tabIndex == 3"
          />
        </div>
      </b-tab>
      <b-tab title="Welcome Screen">
        <b-container class="bg-white py-3">
          <WelcomeScreen ref="welcomeScreen" :fetchVariable="tabIndex == 4" />
        </b-container>
      </b-tab>
      <b-tab title="Customer Menu">
        <div class="bg-white p-3">
          <CustomerMenu ref="customerMenu" :fetchVariable="tabIndex == 5" />
        </div>
      </b-tab>
    </b-tabs>

    <FooterAction routePath="/setting" @submit="saveForm()" />
    <ModalLoading ref="modalLoading" />
  </div>
</template>
<script>
import InputText from "@/components/inputs/InputText";
import ModalLoading from "@/components/modal/ModalLoading";
import ProfileDisplay from "./components/profileDisplay";
import GeneralLabel from "./components/GeneralLabel";
import CustomerMenu from "./components/CustomerMenu";
import WelcomeScreen from "./components/WelcomeScreen";
import InputTextArea from "@/components/inputs/InputTextArea";
export default {
  components: {
    InputText,
    ModalLoading,
    ProfileDisplay,
    InputTextArea,
    GeneralLabel,
    CustomerMenu,
    WelcomeScreen,
  },
  data() {
    return {
      isLoading: true,
      qrOpt: ["Telephone", "Member ID."],
      yearFormat: [
        { value: 1, text: "B.E." },
        { value: 2, text: "A.D." },
      ],
      fontSizeOpt: ["Normal", "Large"],
      layoutOpt: ["Standard", "Tier", "Compact"],

      earnDisplay: ["Point", "Baht"],

      progressDisplay: [
        { text: "Accumulate", value: 0 },
        { text: "Actual", value: 1 },
      ],
      form: {
        memberLiffUrl: "",
        campaignLiffUrl: "",
        qr_code: "Telephone",
        customer_display_transaction_detail: "0",
        line_display_point_channel: "0",
        line_display_member_level: "0",
        line_display_service_outstanding: "0",
        line_display_code_countdown: "0",
        line_display_logout_button: "0",
        line_display_right_request_link: "0",
        display_point_after_redeem: "0",
        line_display_redeem_expire: "0",
        register_title: "",
        login_title: "",
        telephone_title: "",
        telephone_subtitle: "",
        redeem_button: "",
        footer_billing_address_form: "",
        footer_contact_address_form: "",
        footer_register_form: "",
        footer_shipping_address_form: "",
        header_billing_address_form: "",
        header_contact_address_form: "",
        header_register_form: "",
        header_shipping_address_form: "",
        redeem_exceed_quota_per_customer_title: "",
        redeem_exceed_quota_title: "",
        custom_font: "",
        custom_font_name: "",
        custom_font_bold: "",
        custom_font_bold_name: "",
        font_size: "Normal",
      },

      tabIndex: 0,
    };
  },
  mounted() {},

  created() {
    this.getList();
  },
  destroyed() {},
  methods: {
    onFileChange(file, key, name) {
      this.form[name] = file.name;
      this.isDisable = false;

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form[key] = reader.result;
      };
    },
    deleteFile(name, key) {
      this.form[key] = "";
      this.form[name] = "";
    },
    async getList() {
      this.isLoading = false;
      const response = await this.axios(`/setting/getline`);
      this.layoutOpt = response.data.detail.list_line_layout;
      this.form = response.data.detail;
      this.form.custom_font_name = response.data.detail.custom_font_name;
      this.isLoading = true;
    },

    async saveForm() {
      this.$refs.modalLoading.show();
      await this.$nextTick();
      const labelResult = await this.$refs.generalLabel.save();
      const menuResult = await this.$refs.customerMenu.save();
      const welcomeResult = await this.$refs.welcomeScreen.save();
      this.axios.post(`/setting/updateline`, this.form).then((res) => {
        if (res.data.result) {
          this.successAlert().then(
            (val) => val && this.$router.push("/setting")
          );
        } else {
          this.errorAlert(res.data.message);
        }
        this.$refs.modalLoading.hide();
      });
    },
    copyWord(key) {
      if (!this.form[key]) {
        return;
      }
      this.$copyText(this.form[key]);
      // this.$clipboard(this.form[key]);
      this.$refs["tooltip-" + key].$emit("open");
      setTimeout(() => {
        this.$refs["tooltip-" + key].$emit("close");
      }, 500);
    },
  },
};
</script>
<style scoped>
label.title {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.f-20 {
  font-size: 20px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #a59cfc;
}
.tabs-title-spacing {
  /* padding: 0px 15px 0 15px !important; */
  margin: 10px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.btn-fixed {
  min-width: 150px;
}
.my-actions {
  margin: 0 2em;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}

.right-gap {
  margin-right: auto;
}
.icon-copy {
  margin-top: 0.4rem;
  font-size: 20px;
  margin-left: 10px;
  cursor: pointer;
}
.border-preview {
  border: 2px dashed #979797;
  padding: 2rem;
}
.wrap-panel {
  position: relative;
  width: 70%;
  max-width: 370px;
}
.layout {
  width: 100%;
  /* border-radius: 0.75rem; */
  position: relative;
  overflow: hidden;
  height: 100%;
  aspect-ratio: 9 / 19.5;
  /* 
  background: gray; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: whitesmoke;
  /* justify-content: space-between; */
}
.preview-container {
  display: flex;
  justify-content: center;
  /* border: 1px dashed whitesmoke; */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='5' stroke-dasharray='4%2c15' stroke-dashoffset='27' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 0.75rem;
}
.navbar {
  height: 80px;
  width: 100%;
  justify-content: center;
  background-color: var(--theme-navigation-bar-color);
  transition: background-color 0.1s linear;
  box-shadow: 1px 1px 5px -3px black;
}
.logo-img {
  width: auto;
  max-width: 200px;
  height: auto;
  max-height: 65px;
}
</style>
